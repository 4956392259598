<template>
  <div class="dashboard">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'cms-dashboard',
};
</script>

<style lang="scss" scoped>
.dashboard {
  min-height: calc(100vh - 236px);
  color: $grey--dark;
  background: $white;
}
</style>
